import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function ElevateYourChristmasDinner () {
  const shareTitle = 'Local Chef Michael Blackie on his “Christmas Evening At Home” offerings this holiday season and how to get yours.'
  const videoLink = 'https://ottawa.ctvnews.ca/video?clipId=2344749'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Local Chef Michael Blackie on his “Christmas Evening At Home” offerings this holiday season and how to get yours.</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>December 16, 2021</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <a href={videoLink} target="_blank" rel="noreferrer"><img src="/img/press/chef-blackie.jpg" alt="Chef Blackie on CTV news" /></a>
                <div className="snippet">The following preview is from <a href={videoLink} target="_blank" rel="noreferrer">CTV Ottawa</a></div>
                <p>“Trexity, one of the greatest suppliers for delivery in this town, that’s doing fantastic work, and they have it all.” says Chef Michael Blackie, Co-founder of <a href='https://nextfood.ca/' target="_blank" rel="noreferrer">NeXT</a>.</p>
                <a href={videoLink} target="_blank" rel="noreferrer">Watch interview on CTV Ottawa</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/christmas-cheer-breakfast" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Christmas Cheer Breakfast sold out in-person, but plenty of goodies for virtual attendees</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
